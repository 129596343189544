import React from 'react';

import Layout from '../../components/Layout';
import pic1 from '../../assets/images/services/consulting-services.jpg';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ITConsulting = () => (
  <Layout>
    <div id="main">
      <div className="inner box">
        <h1>IT Consulting Services</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        <article className="box">
        <p>
          Our IT consulting services are at the core of everything we do. Our consultants work closely with our clients 
          to understand and define their specific business needs, in order to develop tailored solutions that meet the desired business objectives.
          From designing your IT infrastructure, to helping you in the planning and budgeting of your IT needs 
          for the years to come, we've been doing IT for more than 30 years and are able to find a solution for just about any IT-related issue or challenge.
          Our team of professionals is dedicated to the success of our clients and will work tirelessly to develop the right IT solutions to help your organization.
        </p>
        <Row><Col>
        <Card>
          <Card.Header>Software Application Development</Card.Header>
            <Card.Body>
              <p>
              We develop Beautiful and functional websites, inventory management , products, portals and web applications 
              built on latest technologies and frameworks, delivered without cost and budget overruns
              Mature, sophisticated and well structured programming language, which allows us to produce best-in-class 
              enterprise-grade websites and web applications that are secure, robust, fast and effective.
              We use modern patterns and practices in development keeping scalability and performance and universal standards in mind.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Project Management</Card.Header>
            <Card.Body>
              <p>
                Benefit from turnkey services and solutions as we plan everything, according to your business reality.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>IT Management</Card.Header>
            <Card.Body>
              <p>
                Stay in the know as we help you manage your IT assets so you can easily focus on your business objectives.
              </p>
            </Card.Body>
          </Card>
          </Col></Row>
        <Row><Col>
        <Card>
          <Card.Header>Strategic IT Planning</Card.Header>
            <Card.Body>
              <p>
                Plan and budget appropriately for the future with a strategic IT plan, based on your business objectives.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Mobile App Development</Card.Header>
            <Card.Body>
              <p>
                We offer a unique expertise in Mobile Application Development for various platforms including 
                Android, iPad, iPhone and iPod to today's rapidly expanding global market. 
                Our diversified experience and a strong penchant for innovation and quality within our 
                Mobile Application Development help customers stay ahead of competitors, maintain superior 
                mobility and eliminate obstacles in the path of the development process while saving both time and money. 
                We believe that results come from skilled teams, not armies of people work. 
                With us, you can let your imagination work and we will ensure that our experienced 
                executive team with considerable experience in mobile makes it a reality. 
                Thanks to our Mobile Application Development Team who keep track of the latest trends
                in the mobile market and have developed apps in all the above platforms making our firm reach every handheld user.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Disaster Recovery</Card.Header>
            <Card.Body>
              <p>
                Make sure your data is protected against cyberattacks and natural disaster with a tailored disaster recovery plan.
              </p>
            </Card.Body>
          </Card>
          </Col></Row>
        </article>
      </div>
    </div>
  </Layout>
);

export default ITConsulting;
